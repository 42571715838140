<template>
  <div>
    <div class="font-weight-medium text-h6 mb-3">
      {{ $i18n.t("forms.title") }}
    </div>
    <v-card class="rounded-md mb-4">
      <div class="d-flex align-center justify-start px-4 py-3 flex-wrap">
        <v-btn
          depressed
          @click="refreshItems()"
          :loading="loading.refresh"
          class="mr-2"
        >
          <v-icon left>mdi-refresh</v-icon>
          {{ $i18n.t("btn.refresh") }}
        </v-btn>
        <div
          class="ml-auto"
          v-if="$permission.$can('form.create', ['user', 'service'])"
        >
          <v-btn color="primary" :to="{ name: 'forms-add' }">
            <v-icon left>mdi-plus-circle-outline</v-icon>
            <span>{{ $i18n.t("btn.add") }}</span>
          </v-btn>
        </div>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :loading="loading.list"
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :footer-props="footerProps"
        :items-per-page="itemPerPage"
        sort-by="id"
        sort-desc
      >
        <template v-slot:body="{ items }" v-if="!_.isEmpty(items)">
          <tbody>
            <template v-for="item in items">
              <v-hover :key="item.id" v-slot="{ hover }">
                <tr>
                  <td style="min-width: 160px">
                    <span>
                      {{
                        $moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")
                      }}
                    </span>
                  </td>
                  <td>{{ item.service.name }}</td>
                  <td>{{ item.name }}</td>
                  <td style="min-width: 120px">
                    <template
                      v-if="
                        $permission.$can('form.update', ['user', 'service'])
                      "
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-show="hover || $vuetify.breakpoint.mdAndDown"
                            x-small
                            icon
                            v-bind="attrs"
                            v-on="on"
                            :to="{
                              name: 'forms-edit',
                              params: { id: item.id },
                            }"
                          >
                            <v-icon color="primary">mdi-pencil-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("forms.btn.edit.title") }}</span>
                      </v-tooltip>
                    </template>
                    <template
                      v-if="
                        $permission.$can('form.delete', ['user', 'service'])
                      "
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-show="hover || $vuetify.breakpoint.mdAndDown"
                            x-small
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteForms(item)"
                            class="ml-2"
                            :loading="loading.delete && item.id === formIdSelected"
                          >
                            <v-icon color="primary">mdi-delete-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("forms.btn.delete.title") }}</span>
                      </v-tooltip>
                    </template>
                  </td>
                </tr>
              </v-hover>
            </template>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <confirm-delete-forms
      :dialog="dialog.delete"
      @confirm="confirmDeleteForms"
    ></confirm-delete-forms>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ConfirmDeleteForms from "./../commons/confirm.vue";
import ListMixin from "./../../mixins/list";
export default {
  mixins: [ListMixin],
  data: () => ({
    path: "forms.list",
    dialog: {
      delete: {
        display: false,
        message: null,
      },
    },
    loading: {
      delete: false
    },
    formIdSelected: null,
  }),
  methods: {
    setHeaders() {
      this.headers = [
        {
          text: this.$i18n.t("common.fields.created.title"),
          align: "start",
          sortable: true,
          value: "createdAt",
          width: 160,
        },
        {
          text: this.$i18n.t("common.fields.serviceId.title"),
          align: "start",
          sortable: false,
          value: "service.name",
          width: 160,
        },
        {
          text: this.$i18n.t("common.fields.title.title"),
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: null,
          width: 120,
        },
      ];
    },
    deleteForms(forms) {
      this.formIdSelected = forms.id;
      this.dialog.delete = {
        message: this.$i18n.t("forms.delete.confirm.message"),
        display: true,
      };
    },
    async confirmDeleteForms() {
      this.loading.delete = true;
      try {
        await this.request({
          url: "forms.delete",
          method: "POST",
          data: { formsId: this.formIdSelected },
          messages: {
            204: this.$t("forms.delete.success"),
            403: true,
            400: true,
          },
        });
        this.refreshItems();
      } catch (error) {
        // empty
      }
      this.loading.delete = false;
    },
    ...mapActions({ request: "request", notify: "notification/notify" }),
  },
  components: {
    ConfirmDeleteForms,
  },
};
</script>