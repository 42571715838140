<template>
  <div>
    <forms></forms>
  </div>
</template>


<script>
import Forms from './../../components/forms/list';
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$i18n.t('forms.title'),
    };
  },
  components: {
    Forms
  }
}
</script>