<template>
  <v-dialog v-model="dialog.display" persistent max-width="300">
    <v-card>
      <v-card-text class="pt-5">
        <div class="text-body-1 font-weight-medium">{{ dialog.message }}</div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          class="font-weight-medium"
          @click="close()"
        >{{ $i18n.t('btn.no') }}</v-btn>
        <v-btn
          text
          class="font-weight-bold"
          color="primary"
          @click="confirm()"
        >{{ $i18n.t('btn.yes') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Object,
      default: function() {
        return {
          display: false,
          message: ""
        };
      }
    }
  },
  data: () => ({}),
  methods: {
    confirm() {
      this.$emit("confirm", true);
      this.dialog.display = false;
    },
    close() {
      this.dialog.display = false;
      this.$emit('cancel', true);
    }
  }
};
</script>